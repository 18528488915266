.nav-primary > li:hover ul {
  pointer-events: all;
  opacity: 1;
}
.searchButton {
  background-position: center right;
  position: absolute;
  height: 30px;
  line-height: 30px;
  padding-top: 0px !important;
}
.searchButton :hover {
  background-color: transparent !important;
}

@media (min-width: 0px) and (max-width: 900px) {
  .searchButton {
    display: none !important;
  }
}

.header-search {
  z-index: 6500;
}

.header-search__icon {
  /* background-image: transparent url(./images/icon-search.svg) no-repeat center 
    center/60% 60%;*/
  border: 0;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  padding: 0;
  position: absolute;
  right: 55px;
  text-align: center;
  top: 36px;
  width: 30px;
}

.header-search__icon:focus {
  outline: 0;
}

.header-search__icon:hover {
  background-color: transparent;
}

.is-open .header-search__icon {
  /* background-image: url("/icon-close.svg"); */
}

.header-search__icon svg {
  max-height: 18px;
  max-width: 18px;
  vertical-align: text-top;
}

.is-open .header-search-wrapper {
  background-color: #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  display: block;
  height: 61px;
  left: 0;
  padding: 10px 20px 18px;
  position: absolute;
  right: 0;
  top: 72px;
  width: 100%;
}

.header {
  background-color: #fff;
  border-bottom: 1px solid #f3f3f3;
  height: 75px;
  position: relative;
  z-index: 5000;
}

.header__logo {
  display: inline-block;
  margin: 8px 0 0 20px;
}

.header__logo img {
  max-height: 29px;
  max-width: 160px;
}

.nav-util {
  border-bottom: 1px solid #f3f3f3;
  padding: 7px 20px;
  min-height: 30px;
}

.nav-util__link {
  color: #3d3b3b;
  display: inline-block;
  float: left;
  font-family: Dosis, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  text-decoration: none;
  text-transform: uppercase;
}

.nav-util__link:nth-child(even) {
  float: right;
}

.nav-util__link:hover {
  color: #004a88;
}

.nav-util__link:hover path {
  fill: #004a88;
}

.nav-util__link svg {
  margin-right: 10px;
  max-height: 12px;
  max-width: 12px;
  vertical-align: text-top;
}

.nav__toggle {
  /* background: transparent url("images/svg/gp-hamburger.svg") no-repeatcenter
    center/18px 13px; */
  border: 0;
  height: 30px;
  padding: 0;
  position: absolute;
  right: 14px;
  top: 36px;
  transition: none;
  width: 30px;
}

.nav__toggle:hover {
  background-color: transparent;
}

.is-open > .nav__toggle {
  /* background-image: url(/images/icon-close.svg); */
  /* no-repeat center center/18px  18px; */
}

.nav-primary {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(61, 59, 59, 0.25);
  display: none;
  left: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 74px;
}

.is-open .nav-primary {
  display: block;
}

.nav-primary__item {
  border-bottom: 1px solid #f3f3f3;
}

.nav-primary__item:first-child {
  border-top: 1px solid #f3f3f3;
}

.nav-primary__item a {
  color: #3d3b3b;
  display: block;
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  padding: 11px 20px;
  position: relative;
  text-align: left;
  text-decoration: none;
}

.nav-primary__item a:hover {
  color: #6692b8;
}

.nav-primary__item.is-open > a {
  color: #004a88;
}

.nav-secondary {
  box-shadow: inset 0 0 5px rgba(61, 59, 59, 0.25);
  list-style-type: none;
  margin: 0;
  height: 0;
  overflow: hidden;
  padding: 0;
  top: 0;
  transition: max-height 0.5s;
  transition-delay: 2s;
  -webkit-transition-delay: 2s;
}

.is-open > .nav-secondary {
  height: auto;
}

.nav-secondary__item {
  background-color: #eef3f6;
  border-top: 1px solid #fff;
}

.nav-secondary__item:first-child {
  border-top: 0;
}

.nav-secondary__item a {
  border-bottom: 1px solid #eef3f6;
  border-top: 1px solid #fff;
  color: #3d3b3b;
  font-family: Dosis, sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding-left: 40px;
  text-transform: uppercase;
}

a {
  color: #79ae59;
  text-decoration: none;
}

a:hover {
  color: #618e46;
}

@media (min-width: 640px) {
  body {
    font-size: 18px;
  }

  body.wp-admin {
    font-size: 13px;
  }

  .footer {
    text-align: left;
    padding: 24px 0 18px;
  }

  .footer__logo {
    float: left;
    margin: 0 0 25px 3.7%;
  }

  .footer__copyright {
    clear: both;
    margin-top: 32px;
  }

  .footer-nav {
    clear: left;
    float: left;
    margin: 0 0 32px 3.7%;
    text-align: left;
  }

  .footer-nav__link {
    display: inline-block;
    line-height: 27px;
    margin: 0 0 0 40px;
  }

  .footer-nav__link:first-child {
    margin-left: 0;
  }
}

@media (min-width: 1000px) {
  .header-search__icon {
    background-position: center right;
    position: absolute;
    right: 3.7%;
    top: 57px;
  }
  .header__logo {
    display: inline-block;
    margin: 8px 0 0 2%;
  }
  .is-open .header-search-wrapper {
    box-shadow: none;
    left: auto;
    right: calc(3.7% + 25px);
    top: 32px;
    width: 57.3%;
    z-index: 6500;
  }

  .header {
    box-shadow: 0 1px 2px rgba(61, 59, 59, 0.25);
    height: 100px;
  }

  /* .header__logo {
    margin: 18px 0 0 3.7%;
  } */

  .header__logo img {
    max-height: 38px;
    max-width: 209px;
  }

  .nav-util {
    padding: 5px 3.7%;
    text-align: right;
  }

  .nav-util__link svg {
    margin-top: 2px;
  }

  .nav-util__link {
    float: none;
    font-size: 12px;
    margin-left: 20px;
  }

  .nav-util__link:nth-child(even) {
    float: none;
  }

  .nav {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 6000;
  }

  .nav__toggle {
    display: none;
  }

  .nav-primary {
    background-color: transparent;
    box-shadow: none;
    display: block;
    left: auto;
    right: calc(2%);
    top: 63px;
  }

  .nav-primary__item {
    border: 0;
    display: inline-block;
    padding: 0 4px;
    position: relative;
    vertical-align: top;
  }

  .nav-primary__item:first-child {
    border: 0;
  }

  .nav-primary__item > a {
    border-bottom: 2px solid transparent;
  }

  .nav-primary__item:hover > a,
  .nav-primary__item.is-open > a {
    /* border-bottom: 2px solid #fff; */
    /* color: #6692b8; */
  }

  .nav-primary__item:hover > a:after,
  .nav-primary__item.is-open > a:after {
    left: 0;
    width: 100%;
  }

  .nav-primary__item a {
    font-size: 14px;
    padding: 0 8px 12px;
    text-align: center;
    transition: color 0.5s;
  }

  .nav-primary__item a:after {
    background-color: #d8d8d8;
    bottom: -2px;
    content: "";
    display: inline-block;
    height: 2px;
    left: 50%;
    position: absolute;
    transition: all 0.5s;
    width: 0;
  }

  .nav-primary__item.is-open > a {
    color: #6692b8;
  }

  .nav-secondary {
    background-color: #fff;
    box-shadow: 0 3px 3px rgba(61, 59, 59, 0);
    left: 50%;
    margin-left: -103px;
    position: relative;
    transition: box-shadow 0.5s, max-height 0.5s;
    width: 206px;
  }

  .is-open > .nav-secondary {
    box-shadow: 0 3px 3px rgba(61, 59, 59, 0.3);
  }

  .nav-secondary__item {
    border: 0;
  }

  .nav-secondary__item:first-child a:hover {
    border-top-color: #fff;
  }

  .nav-secondary__item a {
    background-color: #fff;
    padding: 10px;
    transition: background-color 0.3s;
  }

  .nav-secondary__item a:after {
    content: none;
  }

  .nav-secondary__item a:hover {
    background-color: rgba(204, 219, 231, 0.25);
    border-bottom: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    color: #3d3b3b;
  }
}
@media (min-width: 640px) {
  body {
    font-size: 18px;
  }
  .fa-navicon:before,
  .fa-reorder:before,
  .fa-bars:before {
    content: "#AS" !important;
  }
}
@media (max-width: 640px) {
  .header__logo {
    display: inline-block;
    margin: 8px 0 0 -100px !important;
  }
}
@media (max-width: 1000px) {
  .header__logo {
    display: inline-block;
    margin: 8px 0 0 -100px !important;
  }
}
