
.successtitle {
    font: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: green;
}

.errortitle {
    color: red;
    font: 24px Arial, Helvetica, sans-serif;
    font-weight: bold;
}