.dsform {
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
}
.form__input__Text {
  height: 1.4375em !important;
  padding: 6px 0 7px !important;
}
.form__auto_input {
  height: 1.4375em !important;
}
/* .MuiAutocomplete-input {
  height: 1.4375em !important;
  
  padding: 16.5px 14px !important;
} */
/* .MuiInputBase-input {
  height: 1.4375em !important;
  padding: 16.5px 14px;
} */
.formAttribute {
  min-height: 90px !important;
  height: 1.4375em !important;
  padding: 6px 0 7px !important;
}
.sdsForm {
  flex: 1;
  padding: 10px !important;
  display: flex;
  flex-direction: column;
  min-height: 900px;
}
.requestForm {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 200px;
  padding-right: 200px;
  padding-top: 20px;
  gap: 10px;
}
.mainform {
  flex: 1;
  flex-direction: row !important;
  width: 100%;
  gap: 10px;
  justify-content: space-evenly;
  /* margin-top: 10px; */
  flex-wrap: wrap;
}
.leftForm {
  flex: 0.5;
  flex-direction: column;
  gap: 10px;
  justify-content: space-evenly;
  margin-top: 5px;
  flex-wrap: wrap;
}
.rightForm {
  flex: 0.5;
  flex-direction: column;
  gap: 10px;
  justify-content: space-evenly;
  margin-top: 5px;
  flex-wrap: wrap;
}

@media (min-width: 0px) and (max-width: 320px) {
  .requestForm {
    padding: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    gap: 10px;
  }
  .sdsForm {
    flex: 1;
    padding: 10px !important;
    display: flex;
    flex-direction: column;
    min-height: 900px;
  }
  .bgImage {
    display: none;
    flex: 0.2;
    background-size: cover;
    background-position: center;
    background-image: url("/public/images/Background.webp");
  }
  .mainform {
    flex: 1;
    flex-direction: column !important;
    width: 100%;
    gap: 10px;
    justify-content: space-evenly;
    margin-top: 10px;
    flex-wrap: wrap;
  }
}
@media (min-width: 321px) and (max-width: 480px) {
  .sdsForm {
    flex: 1;
    padding: 10px !important;
    display: flex;
    flex-direction: column;
    min-height: 900px;
  }
  .requestForm {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    gap: 10px;
  }
  .bgImage {
    flex: 0.2;
    display: none;
    background-size: cover;
    background-position: center;
    background-image: url("/public/images/Background.webp");
  }
  .mainform {
    flex: 1;
    flex-direction: column !important;
    width: 100%;
    gap: 10px;
    justify-content: space-evenly;
    margin-top: 10px;
    flex-wrap: wrap;
  }
}
@media (min-width: 481px) and (max-width: 600px) {
  .requestForm {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    gap: 10px;
  }
  .sdsForm {
    flex: 1;
    padding: 10px !important;
    display: flex;
    flex-direction: column;
    min-height: 900px;
  }
  .bgImage {
    display: none;
    background-size: cover;
    background-position: center;
    background-image: url("/public/images/Background.webp");
  }
  .mainform {
    flex: 1;
    flex-direction: column !important;
    width: 100%;
    gap: 10px;
    justify-content: space-evenly;
    margin-top: 10px;
    flex-wrap: wrap;
  }
}
@media (min-width: 481px) and (max-width: 770px) {
  .requestForm {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    gap: 10px;
  }
  .bgImage {
    flex: 0.2;
    background-size: cover;
    background-position: center;
    background-image: url("/public/images/Background.webp");
  }
  .mainform {
    flex: 1;
    flex-direction: column !important;
    width: 100%;
    gap: 10px;
    justify-content: space-evenly;
    margin-top: 10px;
    flex-wrap: wrap;
  }
}
@media (min-width: 770px) and (max-width: 1025px) {
  .sdsForm {
    flex: 1;
    padding: 10px !important;
    display: flex;
    flex-direction: column;
    min-height: 900px;
  }
  .requestForm {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    gap: 10px;
  }
  .bgImage {
    flex: 0.2;
    background-size: cover;
    background-position: center;
    background-image: url("/public/images/Background.webp");
  }
  .mainform {
    flex: 1;
    flex-direction: column !important;
    width: 100%;
    gap: 10px;
    justify-content: space-evenly;
    margin-top: 10px;
    flex-wrap: wrap;
  }
}

@media (min-width: 1026px) and (max-width: 1201px) {
  .sdsForm {
    flex: 1;
    padding: 10px !important;
    display: flex;
    flex-direction: column;
    min-height: 900px;
  }
  .bgImage {
    flex: 0.2;
    background-size: cover;
    background-position: center;
    background-image: url("/public/images/Background.webp");
  }
  .requestForm {
    flex: 0.8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 20px;
    gap: 10px;
  }
  .mainform {
    flex: 1;
    flex-direction: row !important;
    width: 100%;
    gap: 10px;
    justify-content: space-evenly;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  .leftForm {
    flex: 1;
    flex-direction: column;
    gap: 10px;
    justify-content: space-evenly;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  .rightForm {
    flex: 1;
    flex-direction: column;
    gap: 10px;
    justify-content: space-evenly;
    margin-top: 10px;
    flex-wrap: wrap;
  }
}

@media (min-width: 1202px) {
  .sdsForm {
    flex: 1;
    padding: 10px !important;
    display: flex;
    flex-direction: column;
    min-height: 900px;
  }
  .bgImage {
    flex: 0.2;
    background-size: cover;
    background-position: center;
    background-image: url("/public/images/Background.webp");
  }
  .requestForm {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 20px;
    gap: 10px;
  }
  .mainform {
    flex: 1;
    flex-direction: row !important;
    width: 100%;
    gap: 10px;
    justify-content: space-evenly;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  .leftForm {
    flex: 1;
    flex-direction: column;
    gap: 10px;
    justify-content: space-evenly;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  .rightForm {
    flex: 1;
    flex-direction: column;
    gap: 10px;
    justify-content: space-evenly;
    margin-top: 10px;
    flex-wrap: wrap;
  }
}
