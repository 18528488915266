.content_wrap .section_title .titlewrapper {
  margin: 0 0 0 2%;
  padding: 0;
}
.content_wrap .section_title {
  height: 68px;
  padding: 18px 0 0 0;
  margin: 0 0 0 1px;
  color: #004d78;
  word-wrap: break-word;
  display: block;
  position: relative;
  background: #dae6eb;
  clear: both;
}
.content_wrap .section_title h2 {
  line-height: 1em;
  display: block;
  padding: 0;
  margin: 0;
  font: 24px Arial, Helvetica, sans-serif;
  font-weight: bold;
}

@media (min-width: 0px) and (max-width: 930px) {
  .content_wrap .section_title {
    height: 68px;
    padding: 14px 0 0 0;
    margin: 0 0 0 1px;
    color: #004d78;
    word-wrap: break-word;
    display: block;
    position: relative;
    background: #dae6eb;
    clear: both;
  }
}
