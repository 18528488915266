.nameheader {
  font-weight: bold;
  font-size: large;
}
.optindes {
  font-size: medium;
  border-style: none;
  padding: 10px 0px;
  min-height: 150px;
  width: 100%;
  word-wrap: break-word;
}
.h3 {
  font-weight: bold;
  font-size: small;
}
.emailInfo {
  font-size: medium;
  /* border: 1px; */
  border-style: none;
  padding: 5px 5px 5px 0px;
  width: 100%;
  word-wrap: break-word;
}
.addEmailButton {
  display: flex !important;
  align-items: center !important;
  gap: 5px;
  background-color: rgb(218, 230, 235) !important;
  text-transform: lowercase;
}
.btblabel {
  padding-left: 7px;
}
.optinHeader {
  background-color: #cae8ea;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  color: #004c78 !important;
  font: 11px Arial, Helvetica, sans-serif;
  font-weight: bold !important;
  letter-spacing: 2px;
}
.optinTableRow {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}
.optinInput {
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}
.optinButtons {
  display: flex;
  gap: 10px;
}
.MuiTableCell-sizeSmall {
  padding: 6px 4px 6px 6px;
}
.submitBtnBackgroung {
  display: flex !important;
  align-items: center !important;
  gap: 5px;
  background-color: rgb(218, 230, 235) !important;
  text-transform: lowercase;
}
.OptOut {
  display: flex !important;
  align-items: center !important;
  gap: 5px;
  height: 40px;
  width: 100px;
  font-size: medium;
  font-weight: bold;
  background-color: rgb(218, 230, 235) !important;
  text-transform: lowercase;
}
.optOut {
  padding-top: 30px;
  display: flex !important;
  flex-direction: column;
  gap: 5px;
}
.MuiTableCell-root {
  display: table-cell;
  padding: 10px 24px 6px 5px !important;
  font-size: 0.875rem;
  text-align: left;
  font-family: "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
}
.container {
  display: flex;
  align-items: center;
}
.validation-error-message {
  color: red;
}
.btnmargin {
  margin-bottom: 10px;
  margin-top: 10px;
}
.emailtable {
  overflow: hidden;
  flex-wrap: wrap;
}
.sdsbutton-optin-search {
  margin-left: 5px;
}
