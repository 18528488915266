.mainOptIn {
  flex: 1;
  margin: 0 auto;
  min-height: 900px;
}
.primaryColor {
  color: #006ca0;
}
#wrapper {
  flex-direction: row;
  position: relative;
  margin: 0 auto;
  text-align: left;
}
.product_main,
#articleMain {
  float: left;
  width: 75%;
  margin-left: 35px;
  padding: 20px 0 0 0;
  min-height: 800px;
}
.product_main .section {
  margin: 0 0px 40px 40px;
  flex-direction: row;
}
.button {
  font-family: Verdana;
  font-weight: bold;
  font-size: 11px;
  width: 120px;
  margin-left: 5px;
  padding: 1px;
}
.table {
  overflow-x: auto;
}
.searchContainer {
  padding-top: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.optionSeachbox {
  width: 350px;
  height: 25px;
}
.optionSearchButton {
  width: 100px;
  height: 25px;
}
@media (min-width: 0px) and (max-width: 930px) {
  .product_main .section {
    margin: 0 0px 40px 40px;
    flex-direction: row;
  }
  .product_main,
  #articleMain {
    float: left;
    width: 100%;
    margin-left: 0px;
    padding: 20px 20px 5px 5px;
    min-height: 800px;
  }
}
