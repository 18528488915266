.footer-nav {
  text-align: center;
}

.footer-nav__link {
  color: #3d3b3b;
  display: block;
  font-family: Dosis, sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.11px;
  margin-top: 32px;
  text-decoration: none;
  text-transform: uppercase;
}

.footer-nav__link:hover {
  color: #004a88;
}
.footer {
  display: flex;
  flex: 1;
  font-family: Dosis, sans-serif;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: left;
  padding: 0px 3.7% !important;
}
.panel {
  width: 100%;
  display: flex;
  flex: 1;
  /* padding: 0% 3.7%; */
  align-items: center;
  flex-direction: row;
}
.leftpanel {
  flex: 0.7;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
}
.rightpanel {
  flex: 0.3;
  gap: 10px;
  display: flex;
  float: right;
  justify-content: end;
  flex-direction: row;
  text-decoration: none;
}
.iconModification {
  font-size: 34px;
  font-weight: 400;
}
.footer-nav__link {
  text-align: left;
  display: inline-block;
  line-height: 27px;
  margin: 0 0 0 25px;
  color: #757373;
  display: block;
  font-family: Dosis, sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
}
.icon__button {
  text-decoration: none;
  color: black;
}
.rights {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #f3f3f3;
  color: #3d3b3b;
  font-family: Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  /* margin-top: 60px; */
  padding-top: 14px;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 700px) {
  .footer-nav__link {
    margin-left: 0 !important;
  }
  .panel {
    display: flex;
    flex: 1;
    font-family: Dosis, sans-serif;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: left;
    padding: 0px 3.7% !important;
  }
  .leftpanel {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 10px;
    justify-content: center;
  }
  .rightpanel {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
  }
}
@media (min-width: 700px) and (max-width: 900px) {
  .panel {
    display: flex;
    flex: 1;
    font-family: Dosis, sans-serif;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: left;
    padding: 0px 3.7%;
  }
  .footer {
    flex-direction: column;
    display: flex;
    flex: 1;
    justify-content: space-between;
    height: 130px;
    align-items: center;
    font-family: Dosis, sans-serif;
    background-color: #fff;
    padding: 20px 0 13px;
    text-align: center;
  }
  .rights {
    border-top: 1px solid #f3f3f3;
    color: #3d3b3b;
    font-family: Arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 75px;
    padding-top: 14px;
    text-align: center;
  }
  .rightpanel {
    flex: 0.3;
    gap: 10px;
    display: flex;
    float: right;
    justify-content: end;
    flex-direction: row;
    text-decoration: none;
  }

  .footer-nav__link {
    color: #3d3b3b;
    display: block;
    font-family: Dosis, sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.11px;
    margin-top: 32px;
    text-decoration: none;
    text-transform: uppercase;
  }
}
@media (min-width: 900px) and (max-width: 1200px) {
  .panel {
    display: flex;
    flex: 1;
    font-family: Dosis, sans-serif;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: left;
    padding: 0px 3.7%;
  }
  .leftpanel {
    display: flex;
    flex: 1;
  }
  .rightpanel {
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}
