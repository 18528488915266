#outerWrapper {
  /* width: 961px; */
  flex: 1;
  margin: 0 auto;
  /* background: url("/public/images/news_bg.2.gif") 0 0 repeat-y; */
  min-height: 900px;
}
.normalLineHeight {
  line-height: normal;
}
#wrapper {
  flex-direction: row;
  position: relative;
  margin: 0 auto;
  text-align: left;
}
.MuiCircularProgress-colorPrimary {
  color: #006ca0 !important;
}
.MuiCircularProgress-root {
  width: 50px !important;
  height: 50px !important;
}
.product {
  width: 80%;
}

.sectionitem,
.qfitem {
  margin: 35px 35px 35px 35px;
}
.maincontent {
  margin: 20px 0 35px 0;
  min-height: 0;
}

.qfitem {
  font: 78% Helvetica, Arial, sans-serif;
  margin-left: 80px;
  margin-right: 80px;
  line-height: 1.3;
}
.rowItem {
  margin-top: 20px;
}

.productSearch {
  display: flex;
  font: 11px Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #002163;
  gap: 10px;
  align-items: center;
  border-left: 1px solid #c1dad7;
  border-right: 1px solid #c1dad7;
  border-bottom: 1px solid #c1dad7;
  border-top: 1px solid #c1dad7;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: left;
  vertical-align: top;
  padding: 6px 6px 6px 5px;
}
.button {
  font-family: Verdana;
  font-weight: bold;
  font-size: 11px;
}
.MuiDataGrid-columnHeaderTitle {
  text-decoration: none;
  font-weight: bold !important;
  color: #004c78 !important;
}
.MuiDataGrid-columnHeaderTitleContainer
  div.MuiDataGrid-columnHeaderTitle
  :hover {
  text-decoration: none;
  font-weight: bold !important;
  color: #da6f3e !important;
}
.MuiDataGrid-iconSeparator {
  color: transparent !important;
}

.downloadbuttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 5px;
}
.sdsrequestFromLink {
  text-decoration: none;
  font-weight: bold;
  color: #004c78;
}
.FormLabel {
  font-weight: bold;
  color: black;
  font-family: Verdana;
  font-size: 8pt;
}
.searchbar {
  margin-left: 5px;
  padding: 20px 0px 20px 2px !important;
  width: 450px;
}
.MuiDataGrid-viewport,
.MuiDataGrid-row,
.MuiDataGrid-renderingZone {
  max-height: fit-content !important;
}

.MuiDataGrid-cell {
  max-height: fit-content !important;
  overflow: auto;
  white-space: initial !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding: 4px 4px 4px 8px;
  border-left: 1px solid #c1dad7;
  border-right: 1px solid #c1dad7;
  border-bottom: 1px solid #c1dad7;
}
.MuiButtonBase-root.MuiAutocomplete-clearIndicator {
  color: #004c78;
}
.MuiDataGrid-root .MuiDataGrid-columnHeaderWrapper {
  background-color: #cae8ea;
  font: 11px Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #002163;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: left;
  vertical-align: top;
  padding: 14px 6px 15px 12px;

  /* background-color: #dae6eb; */
}
.sdsdownloadbuttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 3px;
}
.MuiDataGrid-root {
  flex: 1;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #c1dad7;
  height: 100%;
  display: flex;
  outline: none;
  position: relative;
  font-size: 0.875rem;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 4px;
  flex-direction: column;
  letter-spacing: 0.01071em;
}
/* .MuiDataGrid-root .MuiDataGrid-iconSeparator {
  color: black !important;
} */
@media (min-width: 0px) and (max-width: 930px) {
  .qfitem {
    font: 78% Helvetica, Arial, sans-serif;
    margin-left: 10px;
    margin-right: 10px;
    line-height: 1.5;
  }
  .sectionitem {
    margin: 10px 10px 10px 10px;
  }
  .content_wrap .section_title .titlewrapper {
    margin: 0 0 0 10px !important;
    padding: 0;
  }
}
@media (min-width: 0px) and (max-width: 1000px) {
  .downloadbuttons {
    display: flex;
    flex-direction: column;
    gap: 3px;
    justify-content: left;

    align-items: start;
  }
  /* .sdsdownloadbuttons {
    display: flex;
    flex-direction: column;
    justify-content: left;

    align-items: start;
    gap: 3px;
  } */
}
