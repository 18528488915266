html {
  /* font-family: sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;*/
}

/* body {
  margin: 0;
  font-family: Arial, sans-serif;
  /* font-size: 18px;
  font-weight: 400;
  position: relative;
  text-align: left;
  text-decoration: none; 
} */

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

figcaption,
figure,
main {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

body {
  color: #333;
  font: 78% Helvetica, Arial, sans-serif;
  line-height: 1.5;
}

body.wp-admin {
  font-size: 13px;
}

* {
  box-sizing: border-box;
}
.gridButtons {
  padding: 10px 10px !important;
}
.sdsbutton {
  border: none;
  font-size: 10px;
  color: white;
  background: #006ca0;
  cursor: pointer;
  padding: 15px 40px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  /* border-radius: 25px; */
}
.sdsbutton:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.sdsbutton:before {
  font-family: "FontAwesome";
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  line-height: 2;
  font-size: 190%;
  width: 60px;
  /* border-radius: 25px 0px 0px 25px; */
}
.sdsbutton:active {
  background: #2980b9;
  top: 2px;
}
.sdsbutton-sep-normal {
  padding: 25px 40px 25px 40px !important;
}
.sdsbutton-sep-search {
  padding: 20px 40px 20px 40px !important;
}
.sdsbutton-optin-search {
  height: 38px;
  /* padding: 18px 40px 18px 40px !important; */
}
.buttonwidth {
  width: 100%;
}
.sdssearch-optin-input {
  width: 450px;
  height: 38px;
  font-size: 15px;
  padding-left: 10px;
  /* padding: 18px 10px 18px 40px !important; */
}

.sdsbutton-sep-download {
  padding: 15px 40px 15px 40px !important;
}
.sdsbutton-sep {
  padding: 15px 40px 15px 70px;
}
.icon-cart:before {
  content: "\f07a";
}
.sdsbutton-sep:before {
  background: rgba(0, 0, 0, 0.15);
}
.icon-download:before {
  content: "\f019";
}

.icon-email:before {
  content: "\f0e0";
}
.sdsSubmitbutton {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: end;
}
@media (min-width: 1000px) and (max-width: 1450px) {
  .sdsbutton:before {
    font-family: "FontAwesome";
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 2;
    font-size: 190%;
    width: 0px;
    display: none;
    /* border-radius: 25px 0px 0px 25px; */
  }
  .sdsbutton:after {
    width: 0px;
    content: "";
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }
  .sdsbutton {
    border: none;
    font-size: 10px;
    color: white;
    background: #006ca0;
    cursor: pointer;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 12px;
    font-weight: 700;
    outline: none;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    /* border-radius: 25px; */
  }
  .sdsbutton-sep {
    padding: 15px 15px 15px 15px;
  }
}
@media (min-width: 0px) and (max-width: 1000px) {
  .sdsbutton:before {
    font-family: "FontAwesome";
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 2;
    font-size: 190%;
    width: 0px;
    display: none;
    /* border-radius: 25px 0px 0px 25px; */
  }
  .sdsbutton:after {
    width: 0px;
    content: "";
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }
  .sdsbutton {
    border: none;
    font-size: 8px;
    color: white;
    background: #006ca0;
    cursor: pointer;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 12px;
    font-weight: 700;
    outline: none;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    /* border-radius: 25px; */
  }
  .sdsbutton-sep {
    padding: 10px 10px 10px 10px;
  }
}
