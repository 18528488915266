.outerWrapper {
  /* width: 961px; */
  flex: 1;
  margin: 0 auto;
  /* background: url("/public/images/news_bg.2.gif") 0 0 repeat-y; */
  min-height: 350px;
}
.margin {
  margin: 50px;
}
